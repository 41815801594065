<template>
	<div id="app">
		<router-view :key="key" />
	</div>
</template>
<script>
	export default {
		methods: {
			setRem() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				var html = document.querySelector('html');
				if (flag) {
					html.style.fontSize = html.offsetWidth / 6.4 + "px";
				} else {
					html.style.fontSize = html.offsetWidth / 19.2 + "px";
				}
			},
		},
		computed: {
			key() {
				return this.$route.path + Math.random();
			}
		},
		created() {
			this.setRem()
		},
		mounted() {
			// 改变窗口大小时重新设置 rem
			window.onresize = () => {
				this.setRem()
			}
		}
	}
</script>

<style lang="scss">

</style>
