import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './http'
import './assets/iconfont/iconfont.css'

//ElementUI
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/theme/index.css'
ElementUI.Dialog.props.closeOnClickModal.default = false; // 全局关闭点遮罩关闭弹框
Vue.use(ElementUI);
// 富文本
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//axios
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.prototype.$http = http

import common from '@/common/common'
Vue.prototype.common = common

Vue.config.productionTip = false

//  cancelToken中的cancel函数
Vue.prototype.$httpRequestList = []

//拖拽
import VueDND from 'awe-dnd'
Vue.use(VueDND)

// 让页面回到顶部
router.beforeEach((to, from, next) => {
	// chrome
	document.body.scrollTop = 0;
	// firefox
	document.documentElement.scrollTop = 0;
	// safari
	window.pageYOffset = 0;
	next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
