<template>
	<div class="main-search">
		<div class="search-input">
			<el-input v-model="input" clearable placeholder="请输入零件号或备品名称,支持模糊搜索" @input="changeSearch"
				@blur="blurSearch"></el-input>
			<el-button type="warning" @click="search"><i class="el-icon-search"></i>搜索</el-button>
		</div>
		<div class="search-result" v-if="resultShow">
			<ul>
				<li v-for="(item,index) in searchData" :key="index" @click="searchItem(item)">
					<span class="line1">{{item.name}}</span>
					<em class="line1">{{item.model}}</em>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import http from "../http.js";
	export default {
		// props: ['keyword'],
		props: {
			keyword: String,
			searchData: Array,
			resultShow: Boolean
		},
		data() {
			return {
				input: "",
				hotList: [],
			};
		},
		watch: {
			keyword() {
				this.input = this.keyword
			}
		},
		created() {
			this.getHotList();
		},
		mounted() {
			document.addEventListener('click', (e) => {
				if (e.target.className != 'main-search') {
					this.resultShow = false;
				}
			})
		},
		methods: {
			searchItem(item) {
				// this.resultShow=false
				this.$router.push("/sparesView?id=" + item.id);
				console.log(item)
			},
			getHotList() {
				http.fetchGet("/api/admin/product/hotwords").then((res) => {
					if (res.data.code == 0) {
						this.hotList = res.data.data;
					}
				});
			},
			search() {
				this.$emit("onSearch", this.input.trim());
			},
			changeSearch() {
				this.$emit("changeSearch", this.input);
			},
			blurSearch() {
				this.$emit("blurSearch", this.input)
			},
			hotwordSearch(word) {
				this.input = word;
				this.search();
			},
		},
	};
</script>

<style>
</style>
